<template>
    <div class="action-schedule-agent-arrangeable">
        <div class="title f-bold" v-html="titleMessage" />
        <div class="body bold">기존 약속 내용</div>
        <ScheduleProfile
            v-if="content.date_original && content.meet_place"
            :user="getUser(content)"
            :date="content.date_original"
            :place="content.meet_place"
            :food="foodPrefer"
            class="meeting-info"
            :meetType="meetType"
        />
        <div class="grey-container" v-else>기존 약속 내용이 없습니다.</div>
        <div class="body cancel" v-if="changeType === 'cancel'">
            <div class="body bold">취소 요청 사유</div>
            <div class="grey-container">
                <div v-for="item in content.reason.split('\n')" :key="item">{{ item }}</div>
            </div>
            <div class="body bold">Information</div>
            <div>
                - 부득이한 사유인지 확인한 후, 약속 취소 수수료 여부와 금액을 안내해주세요. <br />- 약속 취소 수수료를
                내더라도 약속 취소를 원하는지 물어봐주고, 그래도 원하면 취소처리 해주세요
            </div>
        </div>
        <div class="body change" v-else>
            <div v-if="content.change_detail === '날짜/시간'">
                <div class="body bold">날짜/시간 변경 희망</div>
                <div class="grey-container">
                    <div v-for="item in content.content.split('\n')" :key="item">{{ item }}</div>
                </div>
            </div>
            <div v-else>
                <div class="body bold">지역/장소 변경 희망</div>
                <div class="grey-container">
                    <div v-for="item in content.content.split('\n')" :key="item">{{ item }}</div>
                </div>
            </div>
            <div class="body bold">변경 요청 사유</div>
            <div class="grey-container">
                <div v-for="item in content.reason.split('\n')" :key="item">{{ item }}</div>
            </div>
            <div class="body bold">Information</div>
            <div>
                - 변경 수수료 부과 대상인지 확인 후, 수수료를 내야하면 고객에게 안내해주고 그래도 변경을 희망하는지
                물어봐주세요. <br />- 변경 요청했다가 약속이 깨질수도 있을것 같으면 고객에게 안내를 해주고 그래도 변경을
                희망하는지 물어봐주세요.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleAgentArrangeable',
    props: ['message'],
    components: {},
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.target_name,
                    birthday: person.birthday,
                    job: person.job,
                }
            }
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        meetType() {
            const content = [
                {
                    id: 1,
                    name: 'meal',
                    title: '식사',
                    selected: false,
                },
                {
                    id: 2,
                    name: 'coffee',
                    title: '커피',
                    selected: false,
                },
                {
                    id: 3,
                    name: 'both',
                    title: '식사 또는 커피',
                    selected: false,
                },
            ]
            return content.find(c => c.name === this.content.meet_type_confirmed).title
        },
        changeType() {
            return this.content.change_type
        },
        titleMessage() {
            if (this.changeType === 'cancel') {
                return '약속 취소를 요청했습니다.'
            } else {
                return '약속 변경을 요청했습니다.'
            }
        },
    },
    methods: {},
}
</script>

<style scoped lang="scss">
.action-schedule-agent-arrangeable {
    .title {
        padding: 0;
    }
    .bold {
        font-weight: 600;
    }
    .grey-container {
        margin-top: 10px;
        background-color: #f7f8f9;
        padding: 10px;
        border-radius: 10px;
    }
    .body {
        padding: 12px 0 0;
    }
    width: 100%;
    padding: 16px;
    .meeting-info {
        width: 100%;
    }
}
</style>
